import './index.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import useFormData from '../../../hooks/useFormData';

import {
  FormInput,
  FormRadio,
  FormInputWithSMS,
  FormDatePicker,
  FormPhoneInput,
  FormPicker,
  FormCheckBox,
} from '../../../components/FormComponents';


import { Toast, Icon, Modal } from 'antd-mobile';
import { axios } from '../../../utils';
import { APPID, APP_DE } from '../../../config';
import { saveUserStore } from '../../../store/user';

const appid = APPID
const de = APP_DE
const alert = Modal.alert;
const radioArr = [
  {
    label: '男士',
    value: '男'
  },
  {
    label: '女士',
    value: '女'
  }
];

const rule = [
  {
    key: 'firstname',
    isRequired: true
  },
  {
    key: 'lastname',
    isRequired: true
  },
  {
    key: 'mobile',
    isRequired: true,
    // regex: /^1\d{10}$/,
    regex: /^[\d]{5,15}$/,
    message: '请输入正确手机号'
  },
  {
    key: 'email',
    isFilledAndValidate: true,
    regex: /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/,
    message: '请输入正确邮箱'
  },
  {
    key: 'smscode',
    isRequired: true
  },
  {
    key: 'dateofbirth',
    isRequired: true
  },
  {
    key: 'gender',
    isRequired: true
  },
  {
    key: 'region',
    isRequired: true
  },
  {
    key: 'clausestatus',
    isRequired: true
  }
];

const Form = (props) => {
  const [form, { setFormData, isFillAll, isValidated, validator, initFormData }] = useFormData({
    openid: '',
    unionid: '',
    firstname: '',
    lastname: '',
    mobile: '',
    smscode: '',
    email: '',
    dateofbirth: '',
    region: '',
    gender: '',
    tag: 'TT38b7ab85-841e-4af7-8f85-7900bd853154',
    clausestatus: 0,
    agreestatus: 0,
    prefix: '',
  }, rule);

  const [regions, setRegions] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [isSubmited, toggleIsSubmitted] = useState(false);

  const sendSmsCodeFn = (phone) => {
    const userPhone = !(form.prefix.includes('+86')) ? encodeURIComponent(form.prefix.split(',')[1]) + phone : phone;
    return axios.get(`/open/api/custom/form/v1/h5/sms/send?phone=${userPhone}`, {
      headers: {
        appid,
        de,
      },
    });
  };

  useEffect(() => {
    initFormData({ ...props.formData, smscode: '' });
  }, []); // eslint-disable-line

  useEffect(() => {
    axios.post('/open/api/custom/form/v1/book/region').then(({ code, data }) => {
      if (code === '00000') {
        const regionData = [];
        setRegions(() => {
          const list = Object.keys(data).map((country) => {
            const item = {
              label: country,
              value: country,
              children: data[country].map((item) => {
                regionData.push(item);
                return { label: item.province, value: item.province };
              })
            };
            return item;
          });
          return list;
        });
        setRegionData(regionData);
      }
    });
  }, []);

  const clickToSubmit = useCallback(() => {
    if (isSubmited) return false;
    if (!isFillAll) return false;
    if (!isValidated) {
      Toast.info(validator[0].message, 1.5);
      return false;
    }
    const body = Object.assign({}, form);
    // console.log(regionData);
    const prefix = form.prefix.split(',')[1];
    body.prefix = prefix;
    body.mobile = prefix + form.mobile;
    const region = body.region;
    let regionItem = null;
    // console.log(region);
    if (/(\([a-zA-Z]{1,}\/)?[a-zA-Z]{1,}/.test(region)) {
      regionItem = regionData.find(({ regionEn }) => (region === regionEn));
    }
    else {
      if (region.includes('/')) {
        const [country, province] = region.split('/');
        regionItem = regionData.find((item) => (item.country === country && item.province === province));
      }
      else {
        // console.log(region);
        regionItem = regionData.find(({ country }) => (region === country));
      }
    }
    // console.log(regionItem);
    body.region = regionItem.regionEn;
    // console.log(body);
    body.callback = () => {
      toggleIsSubmitted(false);
    }
    alert('提示', '确认更新个人信息？', [
      { text: '取消', onPress: () => {
        toggleIsSubmitted(false);

      }},
      { text: '确认', onPress: () => {
        props.onSubmit(body)
        toggleIsSubmitted(true);
      } }
    ]);
    // props.onSubmit(body);
  }, [form, isFillAll, isValidated, validator, props, regionData, isSubmited]);

  function goToPicture(e) {
    e.stopPropagation();
    saveUserStore(form); // 跳转隐私政策时暂存user
    props.history.push('/picture');
  }

  const handleChangePrefix = (value) => setFormData(value, 'prefix');

  const handleSelectRegion = (values) => {
    let region = values.join('/');
    if (values[0].includes(values[1])) {
      region = values[0];
    }
    // console.log(values);
    setFormData(region, 'region');
    // (value) => setFormData(value[0] === value[1] ? value[0] : value.join('/'), 'region')
  };

  const renderRegion = (strings) => {
    if (strings) {
      if (!regionData.length) return [];
      let item;
      if (/(\([a-zA-Z]{1,}\/)?[a-zA-Z]{1,}/.test(strings)) {
        item = regionData.find(({ regionEn }) => (regionEn === strings))||{};
      }
      else {
        item = regionData.find(({ country, province }) => {
          if (strings.includes('/')) {
            return (strings === [country, province].join('/'));
          }
          return country === strings;
        }) || {};
      }
      if (strings.includes('/')) {
        return [item.country, item.province];
      }
      return [item.country];
    }
    return [];
  };

  return (
    <div className="content">
      <div className="form-input-item column-box">
        <div className='column-box-item mr10'>
          <FormInput
            title='姓'
            
            value={form.lastname}
            maxLength={20}
            minLength={1}
            onChange={(val) => setFormData(val, 'lastname')}
           
          />
        </div>
        <div className='column-box-item'>
          <FormInput
            title='名'
            value={form.firstname}
            maxLength={20}
            minLength={1}
            onChange={(val) => setFormData(val, 'firstname')}
          />
        </div>
      </div>
      <div>
        <FormRadio title='称谓' inline value={form.gender} id='gender' options={radioArr} onChange={(val) => setFormData(val, 'gender')} />
      </div>
      <div className='form-input-item'>
        <FormDatePicker title='出生日期'
          value={form.dateofbirth}
          minDate={new Date('1900/01/01')}
          maxDate={new Date()}
          mode='date'
          onChange={(val) => setFormData(val, 'dateofbirth')} />
      </div>
      <div className='form-input-item'>
        <FormPhoneInput
          title='手机号'
          prefix={form.prefix}
          onChangePrefix={handleChangePrefix}
          value={form.mobile}
          onChange={(val) => setFormData(val, 'mobile')}
        />
      </div>
      <div className='form-input-item'>
        <FormInputWithSMS title='短信验证码' phone={form.mobile} prefix={form.prefix} value={form.smscode} sendSmsCodeFn={sendSmsCodeFn} onInputChange={(val) => setFormData(val, 'smscode')} />
      </div>
      <div className='form-input-item'>
        <FormInput
          title='邮箱'
          placeholder='选填'
          value={form.email}
          onChange={(val) => setFormData(val, 'email')}
          minLength={1}
          maxLength={50}
        />
      </div>
      <div className='form-input-item'>
        <FormPicker title='地区/省份'
          joinKey='/'
          cols={2}
          options={regions}
          value={renderRegion(form.region)}
          onOk={handleSelectRegion} />
      </div>
      <div className=''>
        <FormCheckBox checked={form.clausestatus} onClick={(val) => setFormData(val ? 1 : 0, 'clausestatus')}>
          <div>我已阅读、理解并同意
            <span style={{ textDecoration: 'underline' }} onClick={(e) => goToPicture(e)}> 隐私政策</span>。
            </div>
        </FormCheckBox>
      </div>
      <div className=''>
        <FormCheckBox checked={form.agreestatus} onClick={(val) => setFormData(val ? 1 : 0, 'agreestatus')}>
          <div>我希望收到有关Delvaux产品或服务的商业信息。</div>
          <div>Delvaux可能会经电邮、短信、电话向您发送这些信息，也可能在社交媒体或其他数字平台向您发送个性化信息或广告。</div>
        </FormCheckBox>
      </div>
      <div
        className={isFillAll ? 'submit-btn' : 'submit-btn disable'}
        onClick={clickToSubmit}
      >
        {props.isUpdated ? '更新信息' : '提交'}
        {isSubmited ? <Icon type='loading' className='button-loading-icon' /> : null}
      </div>
    </div>
  );
};

export default withRouter(Form);
