import { useCallback, useEffect, useState } from 'react';

function useFormData(val, rules = []) {
  const [formData, setForm] = useState({
    ...val
  });
  const [rulesState] = useState(rules);
  const [isFillAll, setIsFillAll] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [validator, setValidator] = useState([]);

  const checkFormData = useCallback(() => {
    let validatorArr = [];
    let isFillAll = true;
    rulesState.forEach(rule => {
      const { key, isRequired, regex, message, isFilledAndValidate } = rule;
      if (isFilledAndValidate && regex) {
        // console.log(regex.test(formData[key]));
        if (formData[key] && !regex.test(formData[key])) {
          validatorArr.push({
            key,
            message: message || `${key} validation failed`
          });
        }
        return false;
      }
      if (isRequired) {
        if (!formData[key] || (Array.isArray(formData[key]) && formData[key].length === 0)) {
          validatorArr.push({
            key,
            message: message || `${key} is required`
          });
          isFillAll = false;
          return false;
        }
      }
      if (regex) {
        if (!regex.test(formData[key])) {
          validatorArr.push({
            key,
            message: message || `${key} validation failed`
          });
          return false;
        }
      }
    });
    setValidator(validatorArr);
    setIsFillAll(isFillAll);
    setIsValidated(validatorArr.length === 0);
    return validatorArr.length === 0;
  }, [formData, rulesState]);

  useEffect(() => {
    checkFormData();
  }, [checkFormData]);

  const resetFormData = useCallback(() => {
    setForm({
      ...formData,
    });
  }, []); // eslint-disable-line

  const setFormData = useCallback((value, key) => {
    setForm({
      ...formData,
      [key]: value
    });
  }, [formData]);

  const initFormData = useCallback((newFormData = {}) => {
    const endFormData = {
      ...formData
    };
    for (let key in endFormData) {
      endFormData[key] = newFormData[key] || endFormData[key];
    }
    setForm(endFormData);
  }, [formData]);

  return [formData, { setFormData, initFormData, resetFormData, isFillAll, isValidated, validator, checkFormData }];
}

export default useFormData;
