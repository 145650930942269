/*
 * @Author       : cynthiali@chatlabs.com 
 * @Date         : 2024-01-25 09:36:43
 * @LastEditors  : cynthiali@chatlabs.com 
 * @LastEditTime : 2024-02-04 11:22:48
 * @FilePath     : /delvaux_custom_form/src/components/ResultContent/index.js
 */
import React from 'react';
import DelvauxLogo from '../../assets/Delvaux-Logo.png';
import './index.scss';

const ResultContent = (props) => {
  function formatName( lastName, firstName) {
    const regex = /^[a-zA-Z]+$/;
    if (regex.test(lastName + firstName)) {
      return lastName + ' ' + firstName;
    }
    return lastName + firstName;
  }

  // 开发标签样式隔离需要用内联
  const btnStyle = {
    width: '170px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    background: '#000000',
    borderRadius: '20px',
    marginTop: '90px',
    marginLeft: 'auto',
    marginRight: 'auto'
  };

  const appUserName = 'gh_97d4bc7972b0';
  const appPath = 'pages/index/index.html?registered=true&openId=' + props.user.openid;
  const btnText = '生成照片';

  /* const appUserName = 'gh_6c01474d8bf3';
  const appPath = 'pages/index/index?type=game&registered=true&openId=' + props.user.openid;
  const btnText = '生成报告'; */

  return (
    <div className='content logo-section'>
      <img src={DelvauxLogo}
        alt='DelvauxLogo'
        className='logo' />
      <div className='logo-title'>
        <div>亲爱的 {formatName( props.user.lastname, props.user.firstname)}</div>
      </div>
      <div className='logo-desc'>您的 Delvaux 微信账户已经{props.isUpdated ? '修改成功' : '成功关联'}!</div>
      <div className='logo-desc'>期待与您开启 Delvaux 奇幻王国之旅</div>
      <div className='logo-desc'>特为您准备 Delvaux 专属滤镜</div>
      <div className='logo-desc'>共度优雅艺术美学时光！</div>
      <div className='center'>
        <wx-open-launch-weapp
          id="launch-btn"
          username={appUserName}
          path={appPath}
        >
          <script type="text/wxtag-template">
            <div style={btnStyle}>{btnText}</div>
          </script>
        </wx-open-launch-weapp>
      </div>
    </div>
  );
};

export default ResultContent;
