import React from 'react'
import picture from '../../assets/Privacy-Items-0927.png'
import banner from '../../assets/header-banner.png'

import './index.scss'

function Picture() {
  return (
    <div>
      <img src={banner} style={{width: '100%'}} alt="Privacy-Items-0927" />
      <img src={picture} style={{width: '100%'}} alt="Privacy-Items-0927" />
    </div>
  )
}

export default Picture
