/*
 * @Author       : cynthiali@chatlabs.com 
 * @Date         : 2024-01-25 09:36:43
 * @LastEditors  : cynthiali@chatlabs.com 
 * @LastEditTime : 2024-01-25 09:42:39
 * @FilePath     : /delvaux_custom_form/src/config.js
 */
export const isDev = process.env.REACT_APP_ENV === "development";
console.log(isDev, process.env.REACT_APP_ENV);

export const APPID = isDev ? "wx3fd3b6f6b79a0511" : "wx03ce48d488eee505";

export const APP_DE = isDev ? "chatlabs_form_delvaux" : "WeChatBindingFollower";

export const baseURL = isDev
  ? "https://custom-form-staging.chatlabs.net"
  : "https://custom-form.chatlabs.net";

export const redirectUrl = isDev
  ? "https://platformdev-be.chatlabs.net/platform/api/open/authorize/getRedirect"
  : "https://delvauxscrm-be.chatlabs.net/platform/api/open/authorize/getRedirect";

export const REACT_APP_STORAGE_PREFIX = 'del'

export const PUBLIC_URL = '/delvaux'