import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from '../../../utils/axios';
import { Picker, Icon } from 'antd-mobile';

const PhonePrefix = ({ prefix, onChange }) => {
	const [prefixs, setPrefixs] = useState([]);
	const [prefixData, setPrefixData] = useState([]);
	const pickerRef = useRef(null);
	const phonePrefix = useMemo(() => (prefix?.split(',')[1] || '+86'), [prefix]);

	useEffect(() => {
		axios.post('/open/api/custom/form/v1/book/mobile/region').then(({ code, data }) => {
			if (code === '00000') {
				const prefixData = [];
				setPrefixs(() =>
					Object.keys(data).map((continent) => ({
						label: continent,
						value: continent,
						children: data[continent].map((item) => {
							prefixData.push(item);
							return { label: `${item.region} ${item.code}`, value: `${item.id},${item.code}` };
						}),
					}))
				);
				setPrefixData(prefixData);
			}
		});
	}, []);

	useEffect(() => {
		if (prefixData.length && !prefix.includes(',')) {
			const item = prefixData.find(({ code }) =>
				(code === (prefix ? prefix : '+86'))
			);
			onChange(`${item.id},${item.code}`);
		}
	}, [prefix, prefixData, onChange]);

	const handleSelectPrefix = (values) => onChange(values[1]);

	const formatValue = (formatString, list) => {
		if (!list.length) return [];
		const item = list.find(({ code }) => (code.includes(formatString.split(',')[1])));
		// console.log(item);
		if (item) {
			return [item.continent, formatString];
		}
		return [];
	};

	if (!prefixs.length) return null;

	return (
		<Picker
			data={prefixs}
			value={formatValue(prefix, prefixData)}
			onChange={handleSelectPrefix}
			cols={2}
			className='form-picker'
			itemStyle={{ fontSize: '3.2vw' }}
		>
			<div className='mobile-prefix'>
				<div className='prefix-content'>{phonePrefix}</div>
				<div className='down-icon'>
					<Icon type='down' size='md' />
				</div>
				<span ref={pickerRef} />
			</div>
		</Picker>
	);
};

export default PhonePrefix;
