import './App.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import Layout from './components/Layout'
import { PUBLIC_URL } from './config';

function App() {
  return (
    <Router basename={PUBLIC_URL}>
      <Layout />
    </Router>
  )
}

export default App;
