import React from 'react'
import FormItemLabel from '../FormItemLabel'
import { Picker, Icon } from 'antd-mobile'

function FormPicker (props) {
  function formatValueFn (value = []) {
    return Array.isArray(value) ? value.join(props.joinKey || '') : value
  }
  const formatValue = props.format || formatValueFn
  return (
    <div>
      <FormItemLabel isRequired={props.isRequired} title={props.title} />
      <Picker {...props}
              title={''}
              className='form-picker'
              value={Array.isArray(props.value) ? props.value : []}
              data={props.options}>
        <div className='input-line'>
          { props.value?.length > 0 ? <div>{formatValue(props.value)}</div>: <div className='placeholder'>{props.placeholder || '请选择'}</div> }
          <div className='down-icon'>
            <Icon type='down' size='md'/>
          </div>
        </div>
      </Picker>
    </div>
  )
}

export default FormPicker
