import React from 'react';
import FormItemLabel from '../FormItemLabel';
import PhonePrefix from './PhonePrefix';

function FormPhoneInput(props) {
  // const phonePrefix = props.prefix || '+86'
  return (
    <div>
      <FormItemLabel isRequired={props.isRequired} title={props.title} />
      <div className='input-line'>
        <PhonePrefix prefix={props.prefix} onChange={props.onChangePrefix} />
        {/* <div className='input-line-label'>{phonePrefix}</div> */}
        <input type="tel"
          value={props.value}
          className='opacity-input'
          placeholder={props.placeholder ? props.placeholder : `请输入${props.title}`}
          onChange={(e) => props.onChange(e.target.value)} />
      </div>
    </div>
  );
}

export default FormPhoneInput;
