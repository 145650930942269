import { sessionStorage } from '../utils'

let user = null
const userSessionKey = 'wx_user'

function getUserStore () {
  return user || sessionStorage.get(userSessionKey)
}

function saveUserStore (newUser) {
  user = newUser
  sessionStorage.save(userSessionKey, newUser)
  return true
}

function removeUserStore() {
  sessionStorage.remove(userSessionKey)
  user = null
  return true
}

export {
  getUserStore,
  saveUserStore,
  removeUserStore
}
