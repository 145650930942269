import React, { useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import EntryRoute from '../EntryRoute';

import Home from '../../pages/home';
import Auth from '../../pages/auth';
import Picture from '../../pages/picture';
import HomeHook from '../../pages/homeHook';

const Layout = (props) => {
  useEffect(() => {
    if (!window.sessionStorage.isIOS) {
      const isIOS = navigator.userAgent.includes('iPhone');
      window.sessionStorage.setItem('isIOS', isIOS ? 1 : 0);
      window.sessionStorage.setItem('firstEntryUrl', window.location.origin + window.location.pathname);
    }
  }, []);
  return (
    <Switch>
      <Route exact path='/' component={Auth} />
      <Route exact path='/picture' component={Picture} />
      <EntryRoute exact path='/home' component={HomeHook} />
      <EntryRoute exact path='/homehook' component={Home} />
      <Redirect exact from='*' to='/' />
    </Switch>
  );
};

export default Layout;
