import './index.scss';

import React, { useState, useEffect } from 'react';
// import { useHistory } from "react-router-dom";
import HeaderBanner from '../../assets/header-banner.png';
import ResultContent from '../../components/ResultContent';
import Form from './form';
import { Toast/*, Modal*/ } from 'antd-mobile';
import { axios } from '../../utils';
import { APPID, APP_DE } from '../../config';
import { getUserStore, removeUserStore } from '../../store/user';
import wx from 'jweixin-1.6.0';

// const alert = Modal.alert;
const appid = APPID
const de = APP_DE 

const Home = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [user, setUser] = useState(getUserStore() || {});
  const [isUpdated] = useState(!!user.mobile);
  // const history = useHistory();

  function handleSubmit(form) {
    // if (isUpdated) {
      // alert('提示', '确认更新个人信息？', [
      //   { text: '取消', onPress: () => console.log('cancel') },
      //   { text: '确认', onPress: () => updateUserInfo(form) }
      // ]);
    // } else {
      updateUserInfo(form);
    // }
  }

  function goToHome() {
    // history.push('/homehook')
  }

  /* useEffect(() => {
    console.log(user);
  }, [user]); */

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const openid = params.get('openId');
    const unionid = params.get('unionid');
    if (openid === 'om2qfuK2QQ9Waf7HFVhdu2VbmqNo' && unionid === 'ooaI-6VHMHtjGQfdhV2PD2kh_zys') {
      window.location.href = `${window.location.origin}/delvaux/`;
    }
  }, []);

  async function updateUserInfo(form) {
    const data = {
      ...form,
      tagStatus: !isUpdated ? 1 : 0,
      tag: 'TT38b7ab85-841e-4af7-8f85-7900bd853154',
    };
    const res = await axios.post('/open/api/custom/form/v1/create', data, {
      headers: {
        appid,
        de,
      }
    });
    Toast.hide();
    if (res.code !== '00000') {
      Toast.info(res.message, 1.5);
      form.callback?.()
    } else {
      console.log('提交成功');
      setUser(form);
      setIsSubmit(true);
      removeUserStore();
    }
  }

  useEffect(() => {
    console.log(wx.signurl());
    const wxConfigFn = async () => {
      let url = window.location.href;
      const ua = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        // 记录进入app时的url
        // alert('苹果终端设备')
        url = decodeURIComponent(wx.signurl());
      }
      const { code, data } = await axios.post('/open/api/custom/form/v1/wx/sign', { url }, {
        headers: {
          appid,
          de,
        }
      });
      // console.log(wx);
      if (code === '00000') {
        wx.ready(() => {
          // console.log('on ready');
        });
        wx.error((err) => {
          console.log('wx err>>>', err);
        });
        wx.config({
          // debug: true, // 开启会有提示
          appId: data.appid, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.noncestr, // 必填，生成签名的随机串
          signature: data.signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-launch-weapp']
        });
      }
    };
    wxConfigFn();
  }, []);

  return (
    <div>
      {isUpdated && !isSubmit ? <div className='home-tips' onClick={goToHome}>温馨提示：您正在进行个人信息修改</div> : <></>}
      <img src={HeaderBanner}
        alt='banner'
        className="header-banner" />
      {
        isSubmit ?
          <ResultContent user={user}
            isUpdated={isUpdated} /> :
          <Form onSubmit={handleSubmit}
            isUpdated={isUpdated}
            formData={user} />
      }
    </div>
  );
};

export default Home;
