import './index.scss';
import { Component, useEffect } from 'react';
/* import HeaderBanner from '../../assets/header-banner.png';
import ResultContent from '../../components/ResultContent';
import Form from './form'; */
import { getUserStore, removeUserStore } from '../../store/user';
import { axios } from "../../utils";
import DelvauxLogo from "../../assets/Delvaux-Logo.png";

const SubscribeBtn = () => {
  const btnStyle = {
    width: '170px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    background: '#000000',
    borderRadius: '20px',
    marginTop: '90px',
    marginLeft: 'auto',
    marginRight: 'auto'
  };
  useEffect(() => {
    let btn = document.getElementById('subscribe-btn');
    btn.addEventListener('success', function (e) {
      console.log('success', e.detail);
    });
    btn.addEventListener('error', function (e) {
      console.log('fail', e.detail);
      alert(e.detail.errCode);
    });
  }, []);
  return (
    <div className='center'>
      <wx-open-subscribe
        id="subscribe-btn"
        template="D-XRzkbenWtDhxXcJgFklsUAeGGvcoaGNdKl2CGnoRM"
      >
        <script type="text/wxtag-template">
          <div style={btnStyle}>订阅</div>
        </script>
      </wx-open-subscribe>
    </div>
  );
};

class Home extends Component {
  state = {
    isSubmit: false,
    isUpdated: false,
    formObj: {
      openId: '',
      firstName: '',
      lastName: '',
      sex: null,
      birthDate: '',
      phone: '',
      smsCode: '',
      email: '',
      area: '',
      clauseStatus: 0,
      agreeStatus: 0
    }
  };

  componentDidMount() {
    console.log('componentDidMount>>>>', this.props, this.state);
    // this.fillUserInfo()
    const that = this;
    const wxConfigFn = async () => {
      const res = await axios.post('/wx/auth/sign', {
        url: window.entryUrl || window.location.href.split('#')[0]
      });
      if (res.errcode === 200) {
        const { data } = res;
        window.wx.ready(() => {
          console.log('on ready');
          // window.wx.checkJsApi({
          //   jsApiList: ['updateAppMessageShareData'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          //   success: function(res) {
          //     // 以键值对的形式返回，可用的api值true，不可用为false
          //     // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
          //   },
          //   fail: function (err) {
          //     console.log(err)
          //   }
          // })
          that.setState({
            isSubmit: true
          });
        });
        window.wx.error((err) => {
          console.log('wx err>>>', err);
        });
        window.wx.config({
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.noncestr, // 必填，生成签名的随机串
          signature: data.signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
          openTagList: ['wx-open-subscribe']
        });
      }
    };
    wxConfigFn();
  }

  fillUserInfo() {
    const formObj = {
      ...this.state.formObj
    };
    const user = getUserStore() || {};
    const phone = user.phone;
    for (let key in formObj) {
      formObj[key] = user[key] || formObj[key];
    }
    formObj.openId = formObj.openId || '2323242e2asdas';
    this.setState((state, props) => ({
      formObj,
      isUpdated: !!phone
    })
    );
  }

  handleSubmitAfter = (formObj) => {
    removeUserStore();
    this.setState({
      isSubmit: true,
      formObj
    });
  };



  render() {
    const { isSubmit, /* formObj, isUpdated */ } = this.state;
    return (
      <div className='content logo-section'>
        <img src={DelvauxLogo}
          alt='DelvauxLogo'
          className='logo' />
        <div className='logo-title'>
          <div>亲爱的 客户</div>
        </div>
        <div className='logo-desc'>您的 Delvaux 微信账户已经成功关联!</div>
        <div className='logo-desc'>期待与您开启 Delvaux 奇幻王国之旅</div>
        <div className='logo-desc'>特为您准备 Delvaux 专属滤镜</div>
        <div className='logo-desc'>共度优雅艺术美学时光！</div>
        <div className='center'>
          {
            isSubmit ? <SubscribeBtn /> : <div />
          }
        </div>
      </div>
    );
  }
}

export default Home;
