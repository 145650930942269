import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { Route } from 'react-router-dom'
import { getUserStore } from '../../store/user';

function isAuth() {
  if (getUserStore()) {
    return true;
  } else {
    return false;
  }
}

const EntryRoute = ({ component: Component, path, ...rest }) => (
  <Route path={path}
    {...rest}
    render={(props) =>
      isAuth() ? <Component {...props} /> : <Redirect to='/' />
    } />
  // <Route path={ path }
  //        {...rest}
  //        render={(props) =>
  //          <Component {...props} />
  //        }/>
);

export default EntryRoute;
