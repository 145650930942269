import { localStorage, sessionStorage } from './localStorage'
import axios from './axios'

function useQuery(search) {
  return new URLSearchParams(search)
}

export {
  localStorage,
  sessionStorage,
  useQuery,
  axios
}
