import React from 'react';
import FormItemLabel from '../FormItemLabel';

function FormInput(props) {
  return (
    <div>
      {props.title ? <FormItemLabel isRequired={props.isRequired} title={props.title} /> : <></>}
      <input value={props.value}
        type={props.type ?? 'text'}
        className='input-line'
        onClick={(e) => e.target.focus()}
        placeholder={props.placeholder ? props.placeholder : `请输入${props.title || ''}`}
        onChange={(e) => props.onChange(e.target.value, e)}
        maxLength={props.maxLength ?? 100}
        minLength={props.minLength ?? 0}
      />
    </div>
  );
}

export default FormInput;
