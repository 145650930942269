import React from 'react'
import FormItemLabel from '../FormItemLabel'
import { DatePicker, Icon } from 'antd-mobile'

function formatDate(date) {
  const dateObj = typeof(date) === 'string' ? new Date(date) : date
  const pad = n => n < 10 ? `0${n}` : n;
  const dateStr = `${dateObj.getFullYear()}-${pad(dateObj.getMonth() + 1)}-${pad(dateObj.getDate())}`
  // const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}`
  return dateStr
}

function FormDatePicker (props) {
  const dateValue = typeof(props.value) === 'string' ? new Date(props.value.replace(/-/g, '/') || new Date()) : props.value
  return (
    <div>
      <FormItemLabel isRequired={props.isRequired} title={props.title} />
      <DatePicker {...props}
                  className='form-picker'
                  value={dateValue}
                  title=''
                  onChange={(e) => props.onChange(formatDate(e))}>
        <div className='input-line'>
          { props.value ? <div>{formatDate(props.value)}</div>: <div className='placeholder'>请选择</div> }
          <div className='down-icon'>
            <Icon type='down' size='md'/>
          </div>
        </div>
      </DatePicker>
    </div>
  )
}

export default FormDatePicker
