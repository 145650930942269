import { REACT_APP_STORAGE_PREFIX } from "../config"

const prefix = REACT_APP_STORAGE_PREFIX

export const localStorage = {
  // 存
  set (key, val) {
    if (Object.prototype.toString.call(val) !== '[object Undefined]') {
      window.localStorage.setItem(prefix + key, JSON.stringify(val))
    }
  },
  // 取
  get (key) {
    let str = window.localStorage.getItem(prefix + key)
    try {
      return JSON.parse(str)
    } catch (e) {
      return str
    }
  },
  // 删
  del (key) {
    window.localStorage.removeItem(prefix + key)
  }
}

export const sessionStorage = {
  // 存
  save (key, val) {
    if (Object.prototype.toString.call(val) !== '[object Undefined]') {
      window.sessionStorage.setItem(prefix + key, JSON.stringify(val))
    }
  },
  // 取
  get (key) {
    let str = window.sessionStorage.getItem(prefix + key)
    try {
      return JSON.parse(str)
    } catch (e) {
      return str
    }
  },
  // 删
  remove (key) {
    window.sessionStorage.removeItem(prefix + key)
  }
}
