import Axios from 'axios';
import { baseURL } from '../config';
const axios = Axios.create({ baseURL });

axios.interceptors.request.use(function (config) {
  // 对头部进行处理
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  // 请求成功 200  返回数据前
  return response.data;
}, function (error) {
  // 请求失败 400 等等
  return Promise.reject(error);
});

export default axios;
