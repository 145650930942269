import './index.scss';
import React, { useState, useEffect } from 'react';
import FormItemLabel from '../FormItemLabel';
import { Toast } from 'antd-mobile';

function FormInputWithSMS(props) {
  const [time, setTime] = useState(props.time || 60);
  const [pending, setPending] = useState(false);
  const title = props.title;
  const placeholder = props.placeholder ?? '请输入短信验证码';

  useEffect(() => {
    let timeOut = null;
    if (time <= 0) {
      setTime(60);
      return false;
    }
    if (time < 60) {
      timeOut = setTimeout(() => {
        setTime((time) => time - 1);
      }, 1000);
    }
    return () => clearTimeout(timeOut);
  }, [time]);

  async function clickToSendCode() {
    if (time !== 60 || pending) return false;
    const phoneReg = /^[\d]{5,15}$/;
    if (!phoneReg.test(props.phone)) {
      Toast.info('请输入正确手机号', 1.5);
      return false;
    }
    setPending(true);
    Toast.loading('', 0);
    const res = await props.sendSmsCodeFn(props.phone);
    setPending(false);
    Toast.hide();
    if (res.code === '00000') {
      Toast.info('已发送', 1.5);
      setTime((time) => time - 1);
    } else {
      Toast.info(res.message, 1.5);
    }
  }

  const handleOnInputChange = (value) => {
    props.onInputChange(value);
  };

  return (
    <div>
      <FormItemLabel isRequired={props.isRequired} title={title} />
      <div className='form-line-column-box'>
        <input type="tel"
          value={props.value}
          placeholder={placeholder}
          className='input-line'
          onClick={(e) => e.target.focus()}
          onChange={(e) => handleOnInputChange(e.target.value)} />
        <div className='sms-btn' onClick={() => clickToSendCode()}>{time === 60 ? '发送验证码' : time + 'S'}</div>
      </div>
    </div>
  );
}

export default FormInputWithSMS;
