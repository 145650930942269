import React, { useEffect } from 'react';
import { axios, useQuery } from '../../utils';
import { saveUserStore } from '../../store/user';
import loading from '../../assets/loading.gif';
import './style.css';
import { APPID, APP_DE, redirectUrl } from '../../config';

const Auth = (props) => {
  const query = useQuery(props.location.search);
  useEffect(() => {
    console.log('auth>>>>>>', props);
    const unionid = query.get('unionid');
    const openid = query.get('openId');
    console.log(unionid);
    if (!unionid) {
      axios.post(redirectUrl, {
        appId: APPID,
        jumpUrl: window.location.href,
        // scope: 'snsapi_base', // 静默授权
        scope: 'snsapi_userinfo' // 非静默授权
      }).then(res => {
        if (res.code === '00000') {
          window.location.href = res.data.url;
        }
      });
    } else {
      /* https://custom-form-h5-staging.chatlabs.net/delvaux?openId=om2qfuK2QQ9Waf7HFVhdu2VbmqNo&unionid=ooaI-6VHMHtjGQfdhV2PD2kh_zys */
      console.log('openid', openid);
      console.log('unionid', unionid);
      if (openid === 'om2qfuK2QQ9Waf7HFVhdu2VbmqNo' && unionid === 'ooaI-6VHMHtjGQfdhV2PD2kh_zys') {
        window.location.href = `${props.location.origin}/delvaux/`;
      }
      else {
        // alert(`unionId: ${unionid}`);
        const getUserInfo = async () => {
          const url = `/open/api/custom/form/v1/book/query/${unionid}`;
          const res = await axios.post(url, {}, {
            headers: {
              de: APP_DE,
              appid: APPID,
            }
          });
          const userInfo = {
            openid,
            unionid,
          };
          if (res.code === '00000') {
            Object.keys(res.data).forEach((key) => {
              userInfo[key] = res.data[key];
            });
          }
          console.log(userInfo);
          saveUserStore(userInfo);
          props.history.replace('/home');
        };
        getUserInfo();
      }
    }
  }, [props, query]);

  return (
    <div className='container'>
      <img src={loading} alt="" className='loading-icon' />
    </div>
  );
};

export default Auth;
