import React from 'react'

import './index.scss'

function FormItemLabel (props) {
  return (
    <div className='form-input-label'>{props.isRequired ? (<span>*</span>) : ''}{props.title}</div>
  )
}

export default FormItemLabel
