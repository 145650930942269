import React from 'react'
import FormItemLabel from '../FormItemLabel'

import './index.scss'

function FormRadio (props) {
  const random = props.id ?? parseInt(Math.random() * 100)
  const radioArr =  props.options.map((item, index) => {
    return (
      <div className='radio-item'
           key={index}>
        <input type="radio"
               id={`radio-${random}-${index}`}
               value={item.value}
               checked={!!(props.value && props.value === item.value)}
               onChange={() => props.onChange(item.value)}/>
        <label htmlFor={`radio-${random}-${index}`}>{item.label}</label>
      </div>
    )
  })
  return (
    <div className={ props.inline ? 'radio-input-section inline' : 'radio-input-section'}>
      <FormItemLabel isRequired={props.isRequired} title={props.title}/>
      { radioArr }
    </div>
  )
}

export default FormRadio
