import React from 'react'
import {Icon} from 'antd-mobile'

function FormCheckBox (props) {
  const checked = props.checked
  return (
    <div className=''>
      <div className='checkbox-line'
           onClick={(e) => props.onClick(!checked, e)}>
        <div className='checkbox-border'>
          { checked ? <Icon type='check' size='md' /> : ''}
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  )
}

export default FormCheckBox
